<template>
  <div>
    <v-app-bar app dark color="primary">
      <v-btn icon @click="back">
        <v-icon color="white">
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>

      <template v-if="loading">
        <v-skeleton-loader
          :loading="loading"
          width="125px"
          type="text"
        ></v-skeleton-loader>
      </template>

      <v-toolbar-title v-else>{{
        isSameUser && loggedIn ? 'Your Profile' : "User's Profile"
      }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu v-if="isSameUser && loggedIn" bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon color="white">{{ mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <div class="container">
      <template v-if="loading">
        <v-skeleton-loader
          :loading="loading"
          height="400px"
          type="card"
        ></v-skeleton-loader>
      </template>

      <div
        v-else
        class="container mb-4 flex-column d-flex justify-center align-center"
      >
        <div>
          <v-badge top overlap offset-x="35" offset-y="50" color="transparent">
            <template v-slot:badge>
              <v-icon>
                {{ badge() }}
              </v-icon>
            </template>
            <v-list-item-avatar
              class="font-weight-bold white--text"
              size="45px"
              color="pink"
            >
              <v-avatar
                class="font-weight-bold white--text"
                style="font-size: 25px"
                size="45px"
                color="pink"
              >
                <span>
                  {{
                    user.username
                      ? user.username.charAt(0).toUpperCase()
                      : 'poop'
                  }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
          </v-badge>
        </div>

        <div>
          <span class="title" v-show="!editingUsername"
            >{{ user.username ? user.username : 'Poop' }}
          </span>

          <span v-if="isSameUser && loggedIn">
            <v-slide-y-reverse-transition>
              <v-text-field
                v-show="editingUsername"
                hide-details
                :disabled="updateLoading"
                :loading="updateLoading"
                v-model="update"
                @keyup.enter="updateUsername"
                outlined
              ></v-text-field>
            </v-slide-y-reverse-transition>
            <v-btn
              v-show="!editingUsername"
              icon
              small
              color="secondary lighten-1"
              @click="editUsername"
            >
              <v-icon small color="secondary lighten-1">{{ mdiPencil }}</v-icon>
            </v-btn>

            <div v-show="editingUsername">
              <v-btn
                :disabled="updateLoading"
                icon
                color="success lighten-1"
                @click="updateUsername"
              >
                <v-icon color="success lighten-1">{{ mdiCheck }}</v-icon>
              </v-btn>
              <v-btn
                icon
                color="error lighten-1"
                :disabled="updateLoading"
                @click="cancelEdit"
              >
                <v-icon color="error">{{ mdiClose }}</v-icon>
              </v-btn>
            </div>
          </span>
        </div>
        <p class="d-none ma-0 caption grey--text text-center">
          This is who I am get used to it, bish!
        </p>
        <template v-if="!isSameUser && user.circles">
          <div class="mt-2" max-width="280px">
            <p class="caption mb-2 text-center">Circles in common</p>

            <v-chip
              v-for="(group, i) in circlesInCommon"
              :key="i"
              :to="`/c/${group.slug}`"
              small
              class="mx-1 mt-1"
              :color="randomColor"
            >
              {{ group.name }}
            </v-chip>
          </div>
        </template>
      </div>

      <v-tabs v-model="tabs" fixed-tabs>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#posts">
          Posts <v-icon>{{ mdiPostOutline }}</v-icon>
        </v-tab>

        <v-tab href="#badges">
          Badges <v-icon>{{ mdiMedalOutline }}</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <v-tab-item value="posts">
          <posts-tab
            v-if="user.posts.length > 0"
            :posts="user.posts"
          ></posts-tab>
          <div
            v-else
            class="d-flex text-center justify-center align-center"
            style="height: 500px"
          >
            <div class="text-center">
              <img
                class="py-3 empty-folder"
                src="/img/icons/empty-folder.png"
              />
              <span
                class="subtitle-2 d-block text-light lighten mt-5 mb-2"
                style="width: 200px"
                >No Posts
              </span>
              <p></p>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item value="badges">
          <badges-tab
            :badges="user.badges"
            :all_badges="all_badges"
          ></badges-tab>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import http from '@/plugins/http';
import backer from '@/mixins/router-back';
import { PostsTab, BadgesTab } from './tabs';
// icons
import {
  mdiClose,
  mdiPencil,
  mdiDotsVertical,
  mdiMedalOutline,
  mdiPostOutline,
  mdiCheck,
  mdiArrowLeft
} from '@mdi/js';
export default {
  name: 'UserProfile',
  mixins: [backer],
  components: {
    PostsTab,
    BadgesTab
  },
  data: () => ({
    user: {
      posts: [],
      username: ''
    },
    tabs: null,
    editingUsername: false,
    updateLoading: false,
    update: '',
    all_badges: [],
    loading: false,
    // icons
    mdiClose,
    mdiPencil,
    mdiDotsVertical,
    mdiMedalOutline,
    mdiPostOutline,
    mdiCheck,
    mdiArrowLeft
  }),
  methods: {
    getUser(username) {
      this.loading = true;
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      let headers = {
        Authorization: `token ${token}`
      };

      if (!token) {
        headers = {};
      }
      http
        .get(`/v1/user/${username}/retrieve/`, { headers })
        .then(response => {
          const user = {
            username: response.data.data.username,
            posts: response.data.data.posts,
            circles: response.data.data.circles,
            bio: response.data.data.bio
          };

          user.badges = response.data.data.badges.map(badge => {
            return {
              ...badge.badge,
              date_awarded: badge.date_awarded,
              hasIt: true
            };
          });

          this.user = { ...user };
        })
        .catch(error => {
          console.log(
            'Error fetching user ! =>',
            error.response.status || 'poop'
          );
          if (error.response.status == 404) {
            this.$router.push({
              name: '404',
              query: { lost: 'user' },
              params: { '0': this.$route.path }
            });
            return;
          }
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Error fetching User',
            style: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBadges() {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      let headers = {
        Authorization: `token ${token}`
      };

      if (!token) {
        headers = {};
      }
      http
        .get(`/v1/badges/`, { headers })
        .then(response => {
          this.all_badges = response.data.results;
        })
        .catch(error => {
          console.log(
            'Error fetching badges ! =>',
            error.response.status || 'poop'
          );

          this.$store.dispatch('SHOW_TOAST', {
            message: 'Error fetching Badges',
            style: 'error'
          });
        });
    },
    back() {
      if (this.$route.query['updated']) {
        this.$router.push({ name: 'Feed' });
      } else {
        this.$router.back();
      }
    },
    logout() {
      this.$store.dispatch('LOGOUT');
      this.$nextTick(() => {
        this.$router.push({ name: 'Feed' });
      });
    },
    editUsername() {
      this.editingUsername = true;
      this.update = this.user.username;
    },
    updateUsername() {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      const headers = {
        Authorization: `token ${token}`
      };
      this.updateLoading = true;
      const username = this.$route.params['username'];
      http
        .patch(
          `/v1/user/${username}/update/`,
          { username: this.update },
          { headers }
        )
        .then(() => {
          // Now go to the user profile with the new username...
          this.$store.dispatch('FETCH_USER');

          console.log('Username update =>', this.update);

          this.$router.push({
            name: 'User Profile',
            params: { username: this.update },
            query: { updated: true }
          });
        })
        .catch(response => {
          console.log('Error updating user ! =>', response);
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Error updating User',
            style: 'error'
          });
        })
        .finally(() => {
          this.updateLoading = false;
          this.editingUsername = false;
        });
    },
    cancelEdit() {
      // this.update = '';
      this.editingUsername = false;
      this.updateLoading = false;
    },

    badge() {
      let icon = '$baby-face';
      if (this.user.badges) {
        switch (this.user.badges[0].name.trim().toLowerCase()) {
          case 'rookie':
            icon = '$baby-face';
            break;
          case 'regular':
            icon = '$alien';
            break;
          case 'maestro':
            icon = '$smirking-cat';
            break;
          default:
            icon = '$baby-face';
            break;
        }
      }
      return icon;
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    randomColor() {
      const colors = [
        'teal lighten-3',
        'primary lighten-3',
        'pink lighten-2',
        'green lighten-3',
        'cyan lighten-3',
        'blue lighten-3',
        'red lighten-3',
        'yellow lighten-3',
        'brown lighten-2',
        'light-blue lighten-2',
        'indigo lighten-2',
        'purple lighten-3',
        'teal accent-3',
        'primary accent-3',
        'pink accent-2',
        'green accent-3',
        'cyan accent-3',
        'blue accent-3',
        'red accent-3',
        'yellow accent-3',
        'brown accent-2',
        'light-blue accent-2',
        'indigo accent-2',
        'purple accent-3',
        'teal accent-4',
        'primary accent-4',
        'pink accent-1',
        'green accent-4',
        'cyan accent-4',
        'blue accent-4',
        'red accent-4',
        'yellow accent-4',
        'brown accent-1',
        'light-blue accent-1',
        'indigo accent-1',
        'purple accent-4'
      ];

      return colors[Math.round(Math.random() * colors.length)];
    },
    badges() {
      let all_badges = [];
      if (this.user.badges && this.all_badges) {
        all_badges = [...this.user.badges];
        let not_in_common = [];
        not_in_common = this.all_badges.filter(function(c) {
          return !this.user.badges.includes(c);
        });

        all_badges = [...all_badges, ...not_in_common];
      }
      return all_badges;
    },

    loggedInUser() {
      return this.$store.getters.user;
    },

    isSameUser() {
      let isSame = false;
      if (this.loggedIn) {
        if (this.loggedInUser.username) {
          console.log('Here in same user :) !');
          return (
            this.user.username.trim().toLowerCase() ==
            this.loggedInUser.username.trim().toLowerCase()
          );
        }
      }

      return isSame;
    },

    circlesInCommon() {
      let inCommon = [];
      if (this.loggedIn && this.$store.getters.user) {
        let theirCircles = this.user.circles;
        let myCircles = this.$store.getters.user.circles;
        inCommon = theirCircles
          .filter(function(c) {
            return myCircles.findIndex(com => com.name == c.name) == -1
              ? false
              : true;
          })
          .filter(function(e, i, p) {
            // extra step to remove duplicates
            return p.indexOf(e) === i;
          });
      }

      return inCommon;
    }
  },
  mounted() {
    const username = this.$route.params['username'];
    this.getUser(username);
    this.getBadges();
  },
  head() {
    return {
      title: `@${this.$route.params['username']}'s Profile`
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.getUser(to.params.username);
    this.getBadges();

    next();
  }
};
</script>

<style></style>
