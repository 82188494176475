<template>
  <div class="mt-2">
    <p class="grey--text text-center font-weight-medium mb-0">
      {{ badges.length > 0 ? badges.length : 1 }}
      {{ badges.length == 1 || badges.length == 0 ? 'Badge' : 'Badges' }}
    </p>

    <v-row dense>
      <template v-if="badges.length > 0">
        <v-col v-for="badge in fullBadges" :key="badge.name" :cols="6">
          <badge :badge="badge"></badge>
        </v-col>
      </template>
      <template v-else>
        <v-col :cols="6">
          <badge :badge="fallback_badge"></badge>
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
import badge from '@/components/user/badge.vue';
export default {
  name: 'UserBadges',
  components: {
    badge
  },
  props: {
    badges: {
      type: Array
    },
    all_badges: {
      type: Array
    }
  },
  data: () => ({
    fallback_badge: {
      image: 'https://api.mybubbl.me/media/Badge/bubbl_badges_baby-03.png',
      name: 'Rookie',
      date_awarded: new Date('05-08-2020')
    },
    fullBadges: []
  }),
  methods: {
    allBadges() {
      let not_in_common = [];
      let all_badges = this.badges;

      let self = this;

      not_in_common = this.all_badges.filter(c => {
        return !self.badges.find(g => {
          return c.name == g.name;
        });
      });

      this.fullBadges = [...all_badges, ...not_in_common];
    }
  },
  mounted() {
    console.log('Created!');
    this.allBadges();
  }
};
</script>
