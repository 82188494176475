<template>
  <div style="margin-bottom: 55px" class="mt-3">
    <p class="grey--text text-center font-weight-medium mb-0">
      {{ posts.length }} {{ posts.length == 1 ? 'Post' : 'Posts' }}
    </p>
    <post v-for="(post, i) in posts" :key="i" :index="i" :post="post"></post>
  </div>
</template>

<script>
import Post from '@/components/post/post.vue';
export default {
  name: 'UserPosts',
  components: {
    Post
  },
  props: {
    posts: {
      type: Array
    }
  }
};
</script>
