<template>
  <v-card flat :class="{ 'does-not-have-it': !badge.hasIt }">
    <v-img
      :src="badge.image"
      width="131px"
      contain
      class="white--text align-end mx-auto"
    >
    </v-img>

    <!-- <v-card-title v-text="badge.name"></v-card-title> -->

    <v-card-actions class="text-center">
      <div class="text-center font-rubik" style="width: 100%">
        <p
          class="ma-0 secondary--text"
          :class="{
            'font-weight-medium': badge.hasIt,
            'text--lighten-3': !badge.hasIt
          }"
        >
          {{ badge.name }}
        </p>
        <p v-if="badge.hasIt" class="caption grey--text ma-0">
          {{ new Date(badge.date_awarded).toDateString() }}
        </p>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'badge-card',
  props: {
    badge: {
      type: Object
    }
  }
};
</script>

<style scoped>
.does-not-have-it {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
</style>
